// import the original component so that base styles are applied first
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component'
// import our modified styles to override some of the default ones
import './Header.override.style.scss';

class Header extends SourceHeader {
    renderContacts(){
        return ''
    }
}


// re-export all the original exports from the parent theme untouched
export * from 'SourceComponent/Header/Header.component';
export default Header