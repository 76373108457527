// import the original component so that base styles are applied first
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component'
// import our modified styles to override some of the default ones
import { COLUMN_MAP } from './Footer.config';
import ContentWrapper from 'SourceComponent/ContentWrapper';
import './Footer.override.style.scss';

class Footer extends SourceFooter {
    renderColumns() {
        return (
            <ContentWrapper
              isNotSection
              wrapperMix={ { block: 'Footer', elem: 'Columns' } }
              label=""
            >
                { COLUMN_MAP.map(this.renderColumn) }
            </ContentWrapper>
        );
    }

    renderContent() {
        return (
            <div block="Footer" elem="Content">
                { this.renderColumns() }
            </div>
        );
    }
}


// re-export all the original exports from the parent theme untouched
export * from 'SourceComponent/Footer/Footer.component';
export default Footer